import { Component } from 'react'

class Footer extends Component {
  render() {
    return (
      <footer
        style={{
          bottom: 0,
          padding: '15px 0 6px 2px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 'x-small',
          fontFamily: 'Helvetica',
          color: 'grey',
        }}
        role="contentinfo"
      >
        ©2019 Target Brands, Inc. Target and the Bullseye are registered
        trademarks of Target Brands, Inc.
      </footer>
    )
  }
}

export default Footer
