import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Form from '../components/EmailForm'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const styles = {
  card: {
    maxWidth: '430px',
    maxHeight: '630px',
  },
  image: {
    maxWidth: '95%',
    maxWeight: '95%',
    paddingLeft: 10,
  },
  textButton: {
    marginTop: 10,
  },
  form: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  button: {
    color: '#cc0000',
    backgroundColor: 'white',
  },
  label: {
    textTransform: 'none',
    fontFamily: 'Helvetica',
  },
}

class EmailPage extends React.Component {
  render() {
    const { classes, cardData, backToCard } = this.props
    return (
      <div>
        <Container className={classes.card} onClick={(e) => backToCard(e)}>
          <img
            className={classes.image}
            alt={cardData.title}
            src={cardData.img}
          />
        </Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={(e) => backToCard(e)}
            classes={{ root: classes.button, label: classes.label }}
          >
            pick a different e-card
          </Button>
        </div>
        <Container className={classes.form}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          />
          <Form {...this.props} />
        </Container>
      </div>
    )
  }
}

export default withStyles(styles)(EmailPage)
