import axios from 'axios'
import apiConfig from '../config/apiConfig'

export function sendEmail(emailInfo) {
  //console.log('emailinfo' + JSON.stringify(emailInfo) )
  return axios.post(apiConfig.mail.api, emailInfo, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiConfig.mail.key,
    },
  })
}
