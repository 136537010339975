import React from 'react'
import { withAuth } from '@praxis/component-auth'
import LandingPage from './LandingPage'
import EmailPage from './EmailPage'
import Header from '../components/Header'
import Footer from '../components/Footer'
import cardData from '../services/cardData'
import headlineCardData from '../services/headlineCardData'
import Notification from '../components/Notification'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  progress: {
    color: '#cc0000',
    marginLeft: '50%',
    marginTop: '30%',
  },
  loading: {
    zIndex: theme.zIndex.appBar + 10,
    position: 'fixed',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
})

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLandingPage: true,
      selectedCard: {},
      firstName: this.props.auth.session.userInfo.firstName,
      message: '',
      notify: false,
      isLoading: false,
    }
  }

  showLoading = (isload) => {
    console.log('in show loading')
    this.setState({
      isLoading: isload,
    })
  }

  displayLandingPage = (e) => {
    this.setState({
      isLandingPage: true,
      selectedCard: {},
    })
  }

  onCardClick = (cardInfo) => {
    this.setState({
      isLandingPage: false,
      selectedCard: cardInfo,
    })
  }

  showNotification = (shown, message) => {
    this.setState({
      notify: shown,
      message: message,
    })
  }

  render() {
    const { auth, classes } = this.props
    const { logout } = auth
    return (
      <React.Fragment>
        {this.state.isLoading && (
          <div className={classes.loading}>
            <CircularProgress className={classes.progress} />
          </div>
        )}
        <Header
          logout={logout}
          headerClick={(e) => this.displayLandingPage(e)}
        />
        {this.state.isLandingPage ? (
          <LandingPage
            cardsData={cardData}
            onCardClick={this.onCardClick}
            headlineCardsData={headlineCardData}
          />
        ) : null}
        {!this.state.isLandingPage ? (
          <EmailPage
            cardData={this.state.selectedCard}
            backToCard={this.displayLandingPage}
            showNotification={this.showNotification}
            showLoading={this.showLoading}
            {...this.state}
          />
        ) : null}
        <Footer />
        <Notification
          isShown={this.state.notify}
          message={this.state.message}
          showNotification={this.showNotification}
        />
      </React.Fragment>
    )
  }
}

export default withAuth()(withStyles(styles)(Main))
