import merge from 'lodash/merge'

const commonConfig = {
  auth: {
    authorizationPath: '/auth/oauth/v2/authorize',
    logoutPath: '/login/responses/logoff.html',
    redirectUri: `${window.location.origin}`,
    responseType: 'token id_token',
    scope: ['openid profile email'],
    storageType: 'localStorage',
    tokenType: 'Bearer',
  },
}

const envConfigs = {
  qa: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost: 'https://logonservices.iam.perf.target.com',
      clientId: 'ymtgt_npe_im',
      nonce: '1234',
    },
    mail: {
      api: 'https://stage-api.target.com/recognition_emails/v1/',
      //api: 'http://localhost:8080/recognition_emails/v1/',
      key: '63d3707169623482bec788c9ce89caee61dbc783',
    },
  },
  stg: {
    auth: {
      host: 'https://oauth.iam.perf.target.com',
      logoutHost: 'https://logonservices.iam.perf.target.com',
      clientId: 'ymtgt_npe_im',
      nonce: '1234',
    },
    mail: {
      api: 'https://stage-api.target.com/recognition_emails/v1/',
      //api: 'http://localhost:8080/recognition_emails/v1/',
      key: '63d3707169623482bec788c9ce89caee61dbc783',
    },
  },
  prod: {
    auth: {
      host: 'https://oauth.iam.target.com',
      logoutHost: 'https://logonservices.iam.target.com',
      clientId: 'ymtgt_prod_im',
      nonce: '1234',
    },
    mail: {
      api: 'https://api.target.com/recognition_emails/v1/',
      key: '41bfc9ae369ec9e9d05836708848f67799a70d85',
    },
  },
}

const env = process.env.REACT_APP_ENV
//const config = envConfigs['stg']
const config = envConfigs[env]
const apiConfig = merge(commonConfig, config)

export default apiConfig
