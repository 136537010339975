import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField, FormControl, Chip, Button } from '@material-ui/core'
import * as emailApi from '../services/emailService'
import Tooltip from '@material-ui/core/Tooltip'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '0',
    color: '#cc0000',
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    color: '#cc0000',
    backgroundColor: 'white',
  },
  submitBtn: {
    margin: theme.spacing(2),
    color: '#ffffff',
    backgroundColor: '#cc0000',
  },
  label: {
    textTransform: 'none',
    fontFamily: 'Helvetica',
  },
  font: {
    fontFamily: 'Helvetica',
  },
})

class EmailForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toName: '',
      toEmail: '',
      ccEmail: '',
      name: props.firstName,
      to: [],
      useCC: [],
      notes: '',
      isSubmit: false,
      isMobile: false,
    }
  }

  componentDidMount() {
    console.log(window.innerWidth)
    if (!this.state.isMobile && window.innerWidth < 400) {
      this.setState({
        isMobile: true,
      })
    }
  }

  //handleChangeInput = e => {
  //this.setState({
  //[e.target.name]: e.target.value,
  //})
  //}

  handleChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }
  handleChangeToName = () => {
    const { toEmail, toName, to } = this.state

    if (!toEmail.toLowerCase().match(/[\w\d\\.-]+@target.com/)) {
      this.props.showNotification(
        true,
        'Please enter valid target email address in To field.',
      )
      return
    }
    if (!toName) {
      this.props.showNotification(true, 'Please enter To name.')
      return
    }
    if (!toName.toLowerCase().match(/^[a-zA-Z]{1,40}$/)) {
      this.props.showNotification(
        true,
        'Recepient name can be of 40 Characters and should not have special characters or numbers',
      )
      return
    }
    if (to.find((to) => to.email_address === toEmail)) {
      this.props.showNotification(
        true,
        'This email is already there in To list.',
      )
      return
    }

    const updateUseTo = Object.assign(
      {},
      {
        first_name: this.state.toName,
        email_address: this.state.toEmail.toLowerCase(),
      },
    )
    this.setState({
      to: [...this.state.to, updateUseTo],
      toName: '',
      toEmail: '',
    })
  }

  handleChangeCcName = () => {
    const { ccEmail, useCC } = this.state

    if (!ccEmail.toLowerCase().match(/[\w\d\\.-]+@target.com/)) {
      this.props.showNotification(
        true,
        'Please enter valid target email address in CC field.',
      )
      return
    }

    if (useCC.find((cc) => cc.email_address === ccEmail)) {
      this.props.showNotification(
        true,
        'This email is already there in cc list.',
      )
      return
    }

    const updatedUseCC = Object.assign(
      {},
      { email_address: this.state.ccEmail.toLowerCase() },
    )
    this.setState({
      useCC: [...this.state.useCC, updatedUseCC],
      ccEmail: '',
    })
  }

  handleSubmit = () => {
    const { name, toName, toEmail, to, useCC, notes, ccEmail } = this.state

    if (
      (ccEmail && !ccEmail.toLowerCase().match(/[\w\d\\.-]+@target.com/)) ||
      (toName && !toName.toLowerCase().match(/^[a-zA-Z]{2,40}$/)) ||
      name === '' ||
      notes === '' ||
      (to.length === 0 &&
        (toName === '' ||
          toEmail === '' ||
          !toEmail.toLowerCase().match(/[\w\d\\.-]+@target.com/)))
    ) {
      this.setState({
        isSubmit: true,
      })
      return
    }

    let updatedTo = to.length
      ? to
      : [{ first_name: toName, email_address: toEmail.toLowerCase() }]
    let emailInfo = {
      sender_name: name,
      to_persons: updatedTo,
      cc_addresses: useCC,
      message: notes,
      image_name: this.props.cardData.name,
    }
    this.props.showLoading(true)
    emailApi
      .sendEmail(emailInfo)
      .then((response) => {
        this.props.showLoading(false)
        this.props.showNotification(true, 'Email(s) successfully sent')
        this.props.backToCard()
      })
      .catch((error) => {
        this.props.showLoading(false)
        this.props.showNotification(
          true,
          'Error occured while sending email(s). Please try again later.',
        )
      })
  }
  handleDeleteToChip = (data) => {
    this.setState({
      to: this.state.to.filter(
        (chip) => chip.email_address !== data.email_address,
      ),
    })
  }

  handleDeleteCcChip = (data) => {
    this.setState({
      useCC: this.state.useCC.filter(
        (chip) => chip.email_address !== data.email_address,
      ),
    })
  }

  handleToEmailChange = (e) => {
    if (e.key.match(',|Enter|Tab|;| ')) {
      if (e.key !== 'Tab') {
        e.preventDefault()
      }
      this.handleChangeToName()
    }
  }

  handleCcEmailChange = (e) => {
    if (e.key.match(',|Enter|Tab|;| ')) {
      if (e.key !== 'Tab') {
        e.preventDefault()
        this.handleChangeCcName()
      }
    }
  }

  onFocusToNameChange = (e) => {
    if (this.state.toName && this.state.toEmail) {
      e.preventDefault()
      this.handleChangeToName()
    }
  }
  onFocusToChange = (e) => {
    if (this.state.toEmail) {
      e.preventDefault()
      this.handleChangeToName()
    }
  }

  onFocusCcChange = (e) => {
    if (this.state.ccEmail) {
      e.preventDefault()
      this.handleChangeCcName()
    }
  }

  render() {
    const { classes, backToCard } = this.props
    return (
      <div
        className={classes.root}
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: 'Helvetica',
        }}
      >
        <div style={{ maxWidth: '430px' }}>
          <div>
            <div style={{ display: 'flex' }}>
              <span
                style={{
                  fontSize: '15px',
                  fontWeight: '600',
                  color: '#cc0000',
                  padding: '4px 0',
                  fontFamily: 'Helvetica',
                }}
              >
                From:
              </span>
              <FormControl
                style={{
                  width: '100%',
                  margin: '0 10px',
                  fontFamily: 'Helvetica',
                }}
                required
              >
                <TextField
                  classes={{ root: classes.font }}
                  placeholder="your friendly name"
                  helperText="your name"
                  name="name"
                  defaultValue={this.state.name}
                  error={this.state.isSubmit && this.state.name === ''}
                  onChange={this.handleChangeInput}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                  style={{ maxWidth: '180px' }}
                  required
                />
              </FormControl>
            </div>

            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                paddingTop: '12px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: '600',
                    color: '#cc0000',
                    padding: '4px 0',
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexFlow: 'row',
                    fontFamily: 'Helvetica',
                  }}
                >
                  To:
                </span>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {this.state.to.map((data) => {
                    return (
                      <Tooltip title={data.email_address}>
                        <Chip
                          className={classes.font}
                          key={data.first_name}
                          variant="outlined"
                          label={data.first_name}
                          onDelete={() => this.handleDeleteToChip(data)}
                          style={{ marginLeft: '6px', marginTop: '4px' }}
                        />
                      </Tooltip>
                    )
                  })}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: this.state.isMobile ? 'flex-start' : 'center',
                  fontFamily: 'Helvetica',
                  flexFlow: this.state.isMobile ? 'column' : 'row',
                }}
              >
                <FormControl
                  className="mt-8 mb-16"
                  style={
                    this.state.isMobile
                      ? { margin: '5px 10px' }
                      : { margin: '5px 10px', maxWidth: '120px' }
                  }
                  required
                  fullWidth
                >
                  <TextField
                    classes={{ root: classes.font }}
                    placeholder="their first name"
                    helperText="add recipient name"
                    name="toName"
                    value={this.state.toName}
                    error={
                      this.state.isSubmit &&
                      // eslint-disable-next-line
                      !this.state.toName.toLowerCase().match(/^[a-zA-Z]{2,40}$/)
                      // eslint-disable-next-line
                      //(this.state.toName === '' && !this.state.toName.length)
                    }
                    onChange={this.handleChangeInput}
                    onBlur={this.onFocusToNameChange}
                    required
                  />
                </FormControl>
                <FormControl
                  className="mt-12 mb-16"
                  style={{ margin: '5px 10px' }}
                  required
                  fullWidth
                >
                  <TextField
                    classes={{ root: classes.font }}
                    placeholder="their target email"
                    helperText="add recipient email"
                    name="toEmail"
                    type="email"
                    value={this.state.toEmail}
                    error={
                      this.state.isSubmit &&
                      (this.state.toEmail === '' ||
                        !this.state.toEmail.match(/[\w\d.-]+@target.com/)) &&
                      this.state.to.length === 0
                    }
                    onChange={this.handleChangeInput}
                    onKeyDown={this.handleToEmailChange}
                    onBlur={this.onFocusToChange}
                    required
                  />
                </FormControl>
              </div>
              <text
                style={{
                  padding: '5px 0 6px 2px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 'x-small',
                  fontFamily: 'Helvetica',
                  color: 'Gray',
                }}
              >
                Note: e-cards require the full firstname.lastname@target.com
                email address in order to send properly.
              </text>
            </div>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                paddingTop: '6px',
                justifyContent: 'center',
                fontFamily: 'Helvetica',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    color: '#cc0000',
                    padding: '4px 0',
                    fontFamily: 'Helvetica',
                  }}
                >
                  CC:
                </span>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {this.state.useCC.map((data) => {
                    return (
                      <Tooltip title={data.email_address}>
                        <Chip
                          className={classes.font}
                          key={data.email_address}
                          label={data.email_address.replace('@target.com', '')}
                          variant="outlined"
                          onDelete={() => this.handleDeleteCcChip(data)}
                          style={{ marginLeft: '6px', marginTop: '4px' }}
                        />
                      </Tooltip>
                    )
                  })}
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl
                  className="mt-8 mb-16"
                  style={{ margin: '5px 10px' }}
                  required
                  fullWidth
                >
                  <TextField
                    classes={{ root: classes.font }}
                    placeholder="let leaders know too"
                    helperText="add cc addresses"
                    name="ccEmail"
                    color="black"
                    value={this.state.ccEmail}
                    error={
                      this.state.isSubmit &&
                      this.state.ccEmail &&
                      !this.state.ccEmail.match(/[\w\d.-]+@target.com/)
                    }
                    onChange={this.handleChangeInput}
                    onKeyDown={this.handleCcEmailChange}
                    onBlur={this.onFocusCcChange}
                  />
                </FormControl>
              </div>
            </div>
            <div />
            <div style={{ marginTop: '12px' }}>
              <div style={{ margin: '10px 5px' }}>
                <FormControl className="mt-8 mt-16" required fullWidth>
                  <TextField
                    classes={{ root: classes.font }}
                    helperText="thank you note"
                    placeholder="write a personalized thank you note"
                    name="notes"
                    value={this.state.notes}
                    onChange={this.handleChangeInput}
                    error={this.state.isSubmit && this.state.notes === ''}
                    required
                    multiline
                    rowsMax={5}
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              fontFamily: 'Helvetica',
            }}
          >
            <Tooltip title="send e-card">
              <Button
                size="large"
                variant="contained"
                classes={{ root: classes.submitBtn, label: classes.label }}
                onClick={this.handleSubmit}
              >
                Recognize now!
              </Button>
            </Tooltip>
            <Button
              size="small"
              variant="outlined"
              onClick={(e) => backToCard(e)}
              classes={{ root: classes.button, label: classes.label }}
            >
              Back to cards
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(EmailForm)
