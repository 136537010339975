const headlineCardData = [
  {
    imgSquare: require('../images/2024_tt/TT-RecognitionCard_500x500.jpg'),
    img: require('../images/2024_tt/TT-RecognitionCard_900x1260.jpg'),
    name: 'Target_Together_24',
    title: 'Target_Together_24',
  },
  {
    imgSquare: require('../images/2024_tt/TT-ThankYou_500x500.jpg'),
    img: require('../images/2024_tt/TT-ThankYou_900x1260.jpg'),
    name: 'Target_ThankYou_24',
    title: 'Target_ThankYou_24',
  },
]

export default headlineCardData
