const cardData = [
  /*  {
    imgSquare: require('../images/core/Champion_500x500.jpg'),
    img: require('../images/core/Champion_900x1260.jpg'),
    name: 'Champion',
    title: 'Champion',
  },
  {
    imgSquare: require('../images/core/Honor_500x500.jpg'),
    img: require('../images/core/Honor_900x1260.jpg'),
    name: 'Honor',
    title: 'Honor',
  },
  {
    imgSquare: require('../images/core/uplift_500x500.jpg'),
    img: require('../images/core/uplift_900x1260.jpg'),
    name: 'uplift',
    title: 'uplift',
  },
  {
    imgSquare: require('../images/core/celebrate_500x500.jpg'),
    img: require('../images/core/celebrate_900x1260.jpg'),
    name: 'celebrate',
    title: 'celebrate',
  }, */
  {
    imgSquare: require('../images/core/Connection_500x500.jpg'),
    img: require('../images/core/Connection_900x1260.jpg'),
    name: 'Connection',
    title: 'Connection',
  },
  {
    imgSquare: require('../images/core/Drive_500x500.jpg'),
    img: require('../images/core/Drive_900x1260.jpg'),
    name: 'Drive',
    title: 'Drive',
  },
  {
    imgSquare: require('../images/core/Inclusivity_500x500.jpg'),
    img: require('../images/core/Inclusivity_900x1260.jpg'),
    name: 'Inclusivity',
    title: 'Inclusivity',
  },
  {
    imgSquare: require('../images/core/Joy_500x500.jpg'),
    img: require('../images/core/Joy_900x1260.jpg'),
    name: 'Joy',
    title: 'Joy',
  },
  {
    imgSquare: require('../images/core/Guest_500x500.jpg'),
    img: require('../images/core/Guest_900x1260.jpg'),
    name: 'Guest',
    title: 'Guest',
  },
  {
    imgSquare: require('../images/core/Safety_500x500.jpg'),
    img: require('../images/core/Safety_900x1260.jpg'),
    name: 'Safety',
    title: 'Safety',
  },
  {
    imgSquare: require('../images/core/Congrats_500x500.jpg'),
    img: require('../images/core/Congrats_900x1260.jpg'),
    name: 'Congrats',
    title: 'Congrats',
  },
  {
    imgSquare: require('../images/core/WellDone_500x500.jpg'),
    img: require('../images/core/WellDone_900x1260.jpg'),
    name: 'WellDone',
    title: 'WellDone',
  },
  {
    imgSquare: require('../images/core/ThankYou_500x500.jpg'),
    img: require('../images/core/ThankYou_900x1260.jpg'),
    name: 'ThankYou',
    title: 'ThankYou',
  },
  {
    imgSquare: require('../images/core/ThinkingOfYou_500x500.jpg'),
    img: require('../images/core/ThinkingOfYou_900x1260.jpg'),
    name: 'ThinkingOfYou',
    title: 'ThinkingOfYou',
  },
  {
    imgSquare: require('../images/core/MLThankYou_500x500.jpg'),
    img: require('../images/core/MLThankYou_900x1260.jpg'),
    name: 'MLThankYou',
    title: 'MLThankYou',
  },
]

export default cardData
