import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider, ProtectedElement } from '@praxis/component-auth'
import Main from './Pages/Main'
import apiConfig from './config/apiConfig'

export const App = () => {
  return (
    <AuthProvider
      clientId={apiConfig.auth.clientId}
      authorizationUrl={apiConfig.auth.host + apiConfig.auth.authorizationPath}
      logoutUrl={apiConfig.auth.logoutHost + apiConfig.auth.logoutPath}
      isFullPageAuth
      redirectUri={apiConfig.auth.redirectUri}
    >
      <Router>
        <ProtectedElement exact path="/" component={Main} />
      </Router>
    </AuthProvider>
  )
}
export default App
